.product-item-actions {
    .actions-secondary {
        display: none !important;
    }
}

.product-info-main {
    padding-top: 30px;

    .page-title-wrapper .page-title {
        line-height: 1.25;
    }
}

.product.attribute.overview {
    font-size: 19px;
}

.product-info-price {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 5px solid $primary__color__light;
}

.product.info.detailed {
    padding-top: 30px;
}

.product.data.items > .item.content {
    @include media-breakpoint-up(sm) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 30px !important;
        margin-top: 40px;
        border-top: 0;
    }
}

.product.data.items > .item.title > .switch {
    color: #7d7d7d;
    font-weight: 200;
    line-height: 40px;
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    background: #f0f0f0;
    border-bottom: 1px solid #d1d1d1;
    height: 40px;
    padding: 0 40px 0 40px !important;
}

.product.data.items > .item.title {
    margin: 0;
}

.product-info-main .price-box .price-wrapper .price, 
.product-options-bottom .price-box .price-wrapper .price {
    font-weight: 200;
}

.product-info-stock-sku {
    float: right;
    text-align: right;

    .stock {
        color: $primary__color__light;
    }
}

.product-info-main .product.attribute.sku {
    display: none;
}

.product-info-main .stock.available, 
.product-info-main .stock.unavailable {
    margin-right: 0;
}

.product-item-actions .actions-primary {
    display: inline-block;
}

.product-info-main .product-reviews-summary .reviews-actions {
    font-size: 16px;
}

.product-info-main .page-title-wrapper .page-title {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 30px;
}

.product-info-main .box-tocart .action.tocart {
    margin-bottom: 15px;
}

.products-grid .product-item {
    width: 49.5%;
}
