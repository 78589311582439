.bg-image-bj {
    background-image: url("../images/home/bj.png");
    background-repeat: no-repeat;
    background-position: 250px 50px;
}

.bg-grey {
    background-color: gray("200");
    transition: all 0.3s ease;

    &:hover {
        background-color: gray("300");
    }
}

.border-left-orange {
    border-left: 10px solid theme-color("primary");
    background-image: url("../images/home/mg-iso.png");
    background-repeat: no-repeat;
    background-position: 350px bottom;
}

.co-bench {
    background-image: url("../images/home/bench.png");
    background-repeat: no-repeat;
    background-position: 230px 60px;
}

.co-enclosed {
    background-image: url("../images/home/enclosed.png");
    background-repeat: no-repeat;
    background-position: 200px 60px;
}

.co-soldering {
    background-image: url("../images/home/soldering.png");
    background-repeat: no-repeat;
    background-position: 210px 60px;
}
