@media only screen and (max-width: 767px) {
    .nav-sections .switcher,
    .nav-sections .header.links li,
    .navigation .level0 {
        font-size: 1rem;
        color: #fff;
        font-weight: 600;
    }

    .nav-sections .header.links a, 
    .nav-sections .header.links a:hover {
        color: #fff;
        font-weight: 600;
    }
}

.header.links a {
    color: #000;
}

.page-header {
    border-bottom: 1px solid #eee;

    .header.content {
        @include media-breakpoint-up(sm) {
            padding: 40px 15px;
        }

        .minicart-wrapper {
            @include media-breakpoint-up(sm) {
                padding-top: 15px;
            }
        }

        .switcher {
            display: none;

            @include media-breakpoint-up(sm) {
                padding-top: 20px;
                display: inline-block;
                float: right;
            }
        }

        .logo {
            @include media-breakpoint-up(sm) {
                margin-right: 20px;
            }
        }
    }
}

.page-header .switcher .options .action.toggle:after, 
.page-footer .switcher .options .action.toggle:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    display: inline-block;
}

.page-header .switcher .options .action.toggle.active:after, 
.page-footer .switcher .options .action.toggle.active:after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 22px;
    line-height: 22px;
    color: inherit;
    content: "";
    font-family: "icons-blank-theme";
    margin: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
    display: inline-block;
}

.cms-home .sections.nav-sections {
    margin-bottom: 0;
}

.sections.nav-sections {
    background-color: #222021;
}

.navigation {
    background-color: #222021;

    .level0 > .level-top {
        color: #fff;
        font-weight: 600;
        text-transform: none;
        @include media-breakpoint-up(sm) {
            text-transform: uppercase;
        }
    }

    .level0.active > .level-top {
        border-color: #eee !important;
    }

    .level0 > .level-top:hover, 
    .level0 > .level-top.ui-state-focus {
        color: #eee;
    }
}

.navigation a, 
.navigation a:hover {
    @include media-breakpoint-down(sm) {
        color: #ddd;
    }
}

.navigation .submenu:not(:first-child) ul > li a {
    @include media-breakpoint-down(sm) {
        color: #bbb;
    }
}

.product-reviews-summary {
    margin-bottom: 10px;
}

.sparsh_banner {
    @extend .mb-4;
}

.page-header .header.links {
/*
    display: inline-block;
    list-style: none;
    margin-right: 10px;
    margin-bottom: 0;
    padding: 0;

    li {
        display: inline-block;
        margin-right: 10px;
    }
*/
}

#search-btn {
    vertical-align: middle;

    a {
        color: #000;
        font-size: 20px;
    }
}

.user-menu {
    button {
        font-size: 20px;

        &:focus,
        &:hover,
        &:active {
            box-shadow: none;
            background: #fff;
        }
    }

    .dropdown-item {
        color: #212529 !important;
    }
}

.page-header .panel.wrapper {
    display: none;
}

div.block.block-search {
    margin-right: 20px;

    @include media-breakpoint-up(xl) {
        margin-left: 185px;
    }

    input {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 0;

        @include media-breakpoint-up(sm) {
            padding: 30px 25px;
            width: 210px;
        }

        @include media-breakpoint-up(lg) {
            width: 400px;
        }

        @include media-breakpoint-up(xl) {
            width: 450px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .navigation .level0.active > .level-top, 
    .navigation .level0.has-active > .level-top {
        border-width: 0;
        color: #fff;
    }

    .navigation .level0 > .level-top {
        line-height: 70px;
    }
    
    .block-search {
        display: inline-block;
        width: auto;
        float: none;
        margin-right: 30px;

        .action.search {
            right: 18px;
            top: 15px;
        }

        .control {
            border-top: 0;
            margin: 0;
            padding: 0;
        }
    }

    .navigation .level0 .submenu {
        min-width: 340px;

        li {
            margin-bottom: 0;

            a {
                font-size: 15px;
            }
        }
    }
}

.page-header .switcher .options ul.dropdown {
    min-width: 100px;
}

.below-navigation {
    h6 {
        font-weight: 600;
    }

    .fa {
        color: theme-color("primary");
    }

    p {
        font-size: 14px;
    }
}

.slick-slide {
    .btn.btn-banner {
        display: block;
        width: 100%;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 0;
        padding: 0;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}
