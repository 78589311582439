html,
body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
}

b, 
strong {
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
.block-title strong {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

h5 {
    font-weight: 600;
}

h6 {
    //color: $primary__color;
    text-transform: uppercase;
}

.action.primary {
    background: $primary__color !important;
    border: 1px solid $primary__color !important;
    font-size: 1rem !important;
    font-weight: 200;
    text-transform: uppercase;
}

.review-form .action.submit.primary {
    color: #fff;
}

.btn {
    text-transform: uppercase;
}

.product-info-main .action.primary.tocart {
    background: #000 !important;
    border: 1px solid #000 !important;
}

.products-grid .action.primary.tocart {
    background: #e6e6e6 !important;
    border: 1px solid #e6e6e6 !important;
    color: #000 !important;
}

.logo {
    margin-bottom: 0;
}

.page-title-wrapper {
    margin-bottom: 30px;

    h1 {
        margin-bottom: 0;
    }
}

a.btn-primary:visited,
a.btn-dark:visited {
    color: #fff !important;
}

.pt-120 {
    padding-top: 60px;

    @include media-breakpoint-up(lg) {
        padding-top: 120px;
    }
}

.btn {
    border-radius: 0;
}

.gallery-placeholder {
    width: 100%;
}

.section-header {
    text-align: center;
    margin-bottom: 40px;

    h2,
    h6 {
        margin: 0;
    }
}

.breadcrumbs .items {
    font-size: 0.875rem;
}

.breadcrumbs .item:not(:last-child):after {
    vertical-align: middle;
}

.message {
    font-size: 1rem !important;
}

.column.main .abs-discount-block > .title strong, 
.column.main .cart-summary .block > .title strong, 
.cart-summary .column.main .block > .title strong, 
.column.main .paypal-review-discount .block > .title strong, 
.paypal-review-discount .column.main .block > .title strong {
    font-size: 18px;
    font-weight: 400;
}

th.mark {
    background-color: transparent !important;
}

.abs-shopping-cart-items .action.continue, 
.block-cart-failed .action.continue, 
.cart-container .form-cart .action.continue {
    font-size: 1rem;
}

.price-including-tax, 
.price-excluding-tax {
    font-size: 1.2rem;
}

.opc-progress-bar {
    padding-top: 40px;
}

.opc-progress-bar-item > span {
    font-size: 1.2rem;
    color: #000;
}

.opc-progress-bar-item > span:after {
    font-size: 18px !important;
    color: #000;
}

.opc-progress-bar-item._active > span {
    color: #000;
}

.abs-checkout-title, .opc-wrapper .step-title, 
.opc-block-shipping-information .shipping-information-title, 
.opc-block-summary > .title, 
.checkout-agreements-items .checkout-agreements-item-title {
    font-size: 2rem;
}

#account-nav .nav {
    display: initial;
}

.account-nav .item a {
    color: #000;

    &:hover {
        color: #222;
    }
}

tr.row {
    display: table-row !important;
    flex-wrap: unset !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
