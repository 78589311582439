.products-grid {
    .product-item-details {
        text-align: center;
    }

    .price-box {
        text-align: center;
    }
}

.filter-options-content {
    ol {
        li {
            border-bottom: 1px dotted #ccc;
            padding-bottom: 3px;

            &:last-of-type {
                border: 0;
                padding: 0;
            }

            a {
                font-size: 14px;
                color: #666;
            }
        }
    }
}

.product.data.items > .item.title > .switch {
    height: auto !important;
}

.ves-megamenu {
    a {
        span {
            font-size: 14px;
        }
    }
}

.navigation .level0 .submenu a {
    font-weight: 400;
}

.ves-megamenu .level0 .nav-item > a {
    line-height: 1.3 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.navigation .level0 {
    margin: 0;
}

.product-image-container {
    border: 1px solid #ccc;
    display: block;
    margin-bottom: 10px;
    // margin: 0 auto;
}

.product-item .price-box {
    margin: 10px 0 15px;
}

.products-grid .price-box,
.products-grid .product-item-details {
    text-align: left;
}

.product.data.items > .item.title > .switch {
    padding: 0 30px 0 30px !important;
}

.category-image {
    display: none;
}

.pages strong.page,
.pages .item {
    font-size: 1.2rem !important;
}
