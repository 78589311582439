footer.page-footer {
    color: #fff;
    background-color: #222021;

    .footer.content {
        border-top: 0;
    }

    .footer-about {
        padding-top: 100px;
        background: url("../images/logo-white.png") no-repeat 15px 0;
    }

    .footer-contact {
        margin-bottom: 5px;

        p {
            margin-bottom: 0;

            &.phone,
            &.email {
                &::before {
                    font-family: FontAwesome;
                    content: "\f095";
                    display: inline-block;
                    padding-right: 5px;
                    vertical-align: middle;
                    color: $primary__color;
                }
            }

            &.email {
                &::before {
                    content: "\f0e0";
                }
            }
        }

        .fa {
            color: $primary__color;
        }
    }

    a {
        color: #fff;
    }
}

.footer.content .links {
    margin-bottom: 0 !important;
    padding: 0 !important;
}

.footer.content .links > li {
    margin: 0 0 3px;
}

.page-wrapper > .page-bottom {
    background: #eee;
    padding: 50px 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    width: auto;

    .section-above-footer {
        p {
            margin-bottom: 0;
        }

        input {
            background: #fff;
            height: 48px;
            width: 300px;
        }
    }
}

.footer-bottom {
    .cards {
        margin-top: 17px;
    }

    .fa {
        font-size: 25px;
    }
}

.social {
    font-size: 18px;

    a {
        display: inline-block;
        margin-right: 8px;
        color: #fff;
        transition: color 0.5s ease;

        &:hover {
            color: #787878;
        }
    }
}
